


import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import { TEventSettings } from '@/_types/event-settings.type';
import { TranslateResult } from 'vue-i18n';
import CabinetMainPage from '@/_modules/promo-cabinet/components/cabinet-main-page/cabinet-main-page.vue';
import ConfirmUnsavedLeave from '@/views/components/popups/confirm-unsaved-leave.vue';
import HelpCrunchService from '@/_services/help-crunch.service';
import { MENU_PROPERTIES_KEY_NAME } from '@/_modules/promo-cabinet/components/cabinet-event-settings/cabinet-event-settings.vue';
import UtilsHelper from '@/_helpers/utils.helper';
import PageTitle from '@/_components/page-title/page-title.vue';

enum CabinetTab {
  MAIN = 'main',
  LOBBY = 'lobby',
  PROGRAM = 'program',
  MAP = 'map',
  EXPONENTS = 'exponents',
  VISITORS = 'visitors',
  STATISTICS = 'statistics',
  EVENT_SETTINGS = 'eventSettings',
  MEETINGS = 'meetings',
  DISCUSSIONS = 'discussions',
  NEWS = 'news',
}

type TCabinetMenuItem = {
  routeName: string;
  userRouteName?: string;
  title: TranslateResult | string;
  isCurrent: boolean;
  isDisabledByOrganizer: boolean;
  isAllowedByEventsWallet: boolean;
  isStandingOut?: boolean;
}

@Component({
  components: {
    CabinetMainPage,
    ConfirmUnsavedLeave,
    PageTitle,
  },
})
export default class Cabinet extends Vue {

  @Getter('_eventStore/event') public readonly event: TEvent;
  @Getter('_eventStore/eventSettings') public readonly eventSettings: TEventSettings;
  @Getter('_eventStore/isEventSettingsPolled') public readonly isEventSettingsPolled: boolean;

  public readonly CabinetTab: typeof CabinetTab = CabinetTab;

  public get isNavigationVisible(): boolean {
    return !this.$route.meta.hideCabinetNavigation;
  }

  public get isMeetingsMenuItemVisible(): boolean {
    return (this.eventSettings && this.eventSettings.show_meetings);
  }

  public get currentTab(): CabinetTab {
    switch (this.$route.name) {

      case 'promo-page-cabinet-lobby':
        return CabinetTab.LOBBY;

      case 'promo-page-cabinet-exponents':
        return CabinetTab.EXPONENTS;

      case 'promo-page-cabinet-event-map':
        return CabinetTab.MAP;

      case 'promo-page-cabinet-contacts':
        return CabinetTab.VISITORS;

      case 'promo-page-cabinet-event-settings':
        return CabinetTab.EVENT_SETTINGS;

      case 'promo-page-cabinet-main-page':
        return CabinetTab.MAIN;

      case 'promo-page-cabinet-program':
      case 'promo-page-cabinet-program-create':
      case 'promo-page-cabinet-program-edit':
      case 'promo-page-cabinet-program-date':
      case 'promo-page-cabinet-program-date-create':
      case 'promo-page-cabinet-program-date-edit':
        return CabinetTab.PROGRAM;

      case 'promo-page-cabinet-meetings':
      case 'promo-page-cabinet-meetings-contact':
        return CabinetTab.MEETINGS;

      case 'promo-page-cabinet-chats':
        return CabinetTab.DISCUSSIONS;

      case 'cabinet-news':
        return CabinetTab.NEWS;

      default:
        return CabinetTab.EVENT_SETTINGS;

    }
  }

  public get menuItems(): TCabinetMenuItem[] {
    return [
      {
        routeName: 'promo-page-cabinet-event-settings',
        title: this.$t('organizerCabinet.pageMenu.generalSettings'),
        isCurrent: this.currentTab === this.CabinetTab.EVENT_SETTINGS,
        isDisabledByOrganizer: false,
        isAllowedByEventsWallet: true,
        isStandingOut: true,
      },
      {
        routeName: 'promo-page-cabinet-main-page',
        title: this.$t('organizerCabinet.pageMenu.info'),
        isCurrent: this.currentTab === this.CabinetTab.MAIN,
        isDisabledByOrganizer: false,
        isAllowedByEventsWallet: true,
      },
      {
        routeName: 'promo-page-cabinet-program',
        userRouteName: 'promo-program',
        title: this.$t('organizerCabinet.pageMenu.agenda'),
        isCurrent: this.currentTab === this.CabinetTab.PROGRAM,
        isDisabledByOrganizer: !this.getIsShownByRouteName('promo-program'),
        isAllowedByEventsWallet: true,
      },
      {
        routeName: 'promo-page-cabinet-contacts',
        userRouteName: 'promo-contacts',
        title: this.$t('organizerCabinet.pageMenu.visitors'),
        isCurrent: this.currentTab === this.CabinetTab.VISITORS,
        isDisabledByOrganizer: !this.getIsShownByRouteName('promo-contacts'),
        isAllowedByEventsWallet: true,
      },
      {
        routeName: 'promo-page-cabinet-exponents',
        userRouteName: 'promo-page-events-companies',
        title: this.$t('organizerCabinet.pageMenu.hall'),
        isCurrent: this.currentTab === this.CabinetTab.EXPONENTS,
        isDisabledByOrganizer: !this.getIsShownByRouteName('promo-page-events-companies'),
        isAllowedByEventsWallet: true,
      },
      // {
      //   routeName: 'promo-page-cabinet-event-map',
      //   title: this.$t('organizerCabinet.pageMenu.hall'),
      //   isCurrent: this.currentTab === this.CabinetTab.MAP,
      //   isDisabledByOrganizer: false,
      //   isAllowedByEventsWallet: true,
      // },
      {
        routeName: 'promo-page-cabinet-lobby',
        userRouteName: 'promo-live',
        title: this.$t('organizerCabinet.pageMenu.lobby'),
        isCurrent: this.currentTab === this.CabinetTab.LOBBY,
        isDisabledByOrganizer: !this.getIsShownByRouteName('promo-live'),
        isAllowedByEventsWallet: true,
      },
      {
        routeName: 'promo-page-cabinet-chats',
        userRouteName: 'text-chats',
        title: this.$t('sideBar.textChats'),
        isCurrent: this.currentTab === this.CabinetTab.DISCUSSIONS,
        isDisabledByOrganizer: !this.getIsShownByRouteName('text-chats'),
        isAllowedByEventsWallet: true,
      },
      {
        routeName: 'cabinet-news',
        userRouteName: 'news',
        title: this.$t('news.title'),
        isCurrent: this.currentTab === this.CabinetTab.NEWS,
        isDisabledByOrganizer: !this.getIsShownByRouteName('news'),
        isAllowedByEventsWallet: true,
      },
      {
        routeName: 'promo-page-cabinet-meetings',
        title: this.$t('organizerCabinet.pageMenu.meetings'),
        isCurrent: this.currentTab === this.CabinetTab.MEETINGS,
        isDisabledByOrganizer: !this.getIsShownByRouteName('promo-page-calendar'),
        isAllowedByEventsWallet: this.isMeetingsMenuItemVisible,
      },
    ].filter(item => item.isAllowedByEventsWallet);
  }

  public get layout(): any {
    return (this.eventSettings && this.eventSettings.layout) || null;
  }

  public get menuProps(): any {
    return (this.layout && this.layout[MENU_PROPERTIES_KEY_NAME]) || null;
  }

  public mounted(): void {
    const chat = document.querySelector('.open-chat');
    if (chat) {
      chat.addEventListener('click', this.openSupportChat);
    }
  }

  public beforeDestroy(): void {
    const chat = document.querySelector('.open-chat');
    if (chat) {
      chat.removeEventListener('click', this.openSupportChat);
    }
  }

  public openSupportChat(event: Event): void {
    if (!event || !event.target || !(event.target as HTMLElement).classList.contains('link')) {
      return;
    }

    const helpCrunchInstance = HelpCrunchService._helpCrunch;
    if (!helpCrunchInstance) { // TODO: also check for instance.isInitialized and test it
      window.setTimeout(() => {
        this.openSupportChat(event);
      }, 1000);
      return;
    }
    helpCrunchInstance('openChat');
  }

  public getIsShownByRouteName(routeName: string): boolean {
    if (!this.menuProps) {
      return true;
    }
    const menuPropsForRouteName: any = (this.menuProps[UtilsHelper.routeNamesToSettingsKeys(routeName)]) || null;
    if (menuPropsForRouteName && Object.hasOwnProperty.call(menuPropsForRouteName, 'isShown')) {
      return menuPropsForRouteName.isShown;
    }
    return true;
  };
}
