<template>
  <transition name="anim-unsaved">
    <div class="confirm-unsaved-leave" v-if="isConfirmLeavePopupShown">
      <div class="content">
        <h3 class="h">{{ $t('confirmLeaveEditPopup.title') }}</h3>
        <p class="descr">{{ $t('confirmLeaveEditPopup.subtitle') }}</p>
        <div class="actions">
          <button class="btn btn-leave" @click="leave">{{ $t('button.leave') }}</button>
          <button class="btn btn-stay" @click="hideConfirmLeavePopup">{{ $t('button.stayOnPage') }}</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'confirm-unsaved-leave',
  computed: {
    ...mapGetters('editFormStore', {
      hasUnsavedChanges: 'hasUnsavedChanges',
      isConfirmLeavePopupShown: 'isConfirmLeavePopupShown',
    }),
  },
  methods: {
    ...mapActions('editFormStore', {
      showConfirmLeavePopup: 'showConfirmLeavePopup',
      hideConfirmLeavePopup: 'hideConfirmLeavePopup',
      setHasUnsavedChanges: 'setHasUnsavedChanges',
      leave: 'leave'
    }),
  },
}
</script>

<style scoped lang="scss">
.confirm-unsaved-leave {
  position: fixed;
  z-index: $z-index-confirm-leave;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    padding: 3rem;
    background-color: #fff;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.25);
    text-align: center;

    .h {
      font-size: 2rem;
      font-weight: normal;
      margin: 0 0 0.25em;
      padding: 0;
    }

    .descr {
      font-size: 1.7rem;
      margin: 0 0 2.4rem;
      font-weight: 300;
    }

    .actions {
      .btn {
        border-radius: 99px;
        padding: 1rem 2rem;
        display: inline-block;
        margin: 0.25rem;
        cursor: pointer;
        color: #fff;
        font-size: 1.6rem;
        font-weight: normal;
        border-style: none;

        &:hover,
        &:focus {
          opacity: 0.6;
        }

        &-leave {
          color: $ew-red-lighter;
        }

        &-stay {
          background-color: $ew-blue;
        }

      }
    }

  }
}

.anim-unsaved-enter-active {
  transition: all 0.2s ease;
}

.anim-unsaved-leave-active {
  transition: all 0.3s ease;
}

.anim-unsaved-enter,
.anim-unsaved-leave-to {
  opacity: 0;
}

</style>
